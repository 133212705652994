import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

const Aside = () => {
    library.add(faChartLine);
    const [pro, setPro] = useState(true)
    const [date, setDate] = useState('')

    const checkPro = async () => {
        let user = Cookies.get('user')
        user = JSON.parse(user)
        setPro(user.plan)
        setDate(user.datePlan)
    }

    useEffect(() => {
        checkPro()
    }, [])

    return (
        <aside class="left-sidebar">
            <div>
                <div class="brand-logo d-flex align-items-center justify-content-between">
                    <a href="/" class="text-nowrap logo-img">
                        <img src="../../assets/images/logos/platformbotlogo.png" width="180" alt="" />
                    </a>
                    <div class="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
                        <i class="ti ti-x fs-8 close-btn"></i>
                    </div>
                </div>
                <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
                    <ul id="sidebarnav">
                        <li class="nav-small-cap">
                            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                            <span class="hide-menu">Menu</span>
                        </li>
                        <li class="sidebar-item">
                            <a class="sidebar-link" href="/users" aria-expanded="false">
                                <span>
                                    <i class="ti ti-user"></i>
                                </span>
                                <span class="hide-menu">Users</span>
                            </a>
                        </li>
                    </ul>
                    <div></div>
                </nav>
            </div>
        </aside>
    )
}

export default Aside