import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    Cookies.remove('authToken');
    Cookies.remove('user');
  },[])

  const login = async () => {
    setLoading(true);
    const requestData = {
      email,
      password
    };

    let userActive = await fetch(`${process.env.REACT_APP_API_WEB_BOOT}auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' // Indica que estamos enviando JSON
      },
      body: JSON.stringify(requestData)
    })

    const user = await userActive.json();
    if (userActive.status !== 200) {
        setMessage("Usuário ou senha inválidos");
        setLoading(false);
        return
    }
    Cookies.set('authToken', user.token)
    navigate('/users');  
  }

  return (
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      <div
        class="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center justify-content-center w-100">
          <div class="row justify-content-center w-100">
            <div class="col-md-8 col-lg-6 col-xxl-3">
              <div class="card mb-0">
                <div class="card-body">
                  <a href="/" class="text-nowrap logo-img text-center d-block py-3 w-100">
                    <img src="../assets/images/logos/platformbotlogo.png" width="180" alt="" />
                  </a>
                  <p class="text-center">Admin - Painel</p>
                  <div class="mb-3">
                    <label class="form-label">Email</label>
                    <input onChange={e => setEmail(e.target.value)} type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                  </div>
                  <div class="mb-4">
                    <label for="exampleInputPassword1" class="form-label">Password</label>
                    <input onChange={e => setPassword(e.target.value)} type="password" class="form-control" />
                  </div>
                  {message && <div class="alert alert-danger" role="alert">
                    {message}
                  </div>}
                  <button disabled={loading} onClick={login} class="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2">{loading ? "Carregando..." : "Login"}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login