import React, { useEffect, useState } from 'react';
import getUsers from '../../helper/user/getUsers';
import getConfig from '../../helper/user/getConfig';
import getPlan from '../../helper/user/getPlan';
import getScheduling from '../../helper/user/getScheduling';
import updateUsers from '../../helper/user/updateUsers';
import updateConfig from '../../helper/user/updateConfig';
import updatePlan from '../../helper/user/updatePlan';
import { useParams } from 'react-router-dom';

const View = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState();
    const [userDetails , setUserDetails] = useState({});
    const [userConfig, setUserConfig] = useState({});
    const [userPlan, setUserPlan] = useState({});
    const [userScheduling, setUserScheduling] = useState([]);

    const fetchData = async () => {
        setLoading(true);
        const users = await getUsers(`?id=${id}`);
        const config = await getConfig(`?user_id=${id}`);
        const plan = await getPlan(`?user_id=${id}`);
        const scheduling = await getScheduling(`?user_id=${id}`);
        setUserScheduling(scheduling.datas);
        setUserConfig(config.datas[0]);
        setUserDetails(users);
        setUserPlan(plan);
        setLoading(false);
    };

    const updateUser = async () => {
        const body = {
            id,
            ...userDetails,

        }
        const response = await updateUsers(body);
        
        if(!response.error){
            alert('Update success')
            return
        }

        alert('Error on update')
    }

    const updateConfigData = async () => {
        const body = {
            user_id: id,
            ...userConfig,

        }
        const response = await updateConfig(body);
        
        if(!response.error){
            alert('Update success')
            return
        }

        alert('Error on update')
    }

    const updatePlanData = async () => {
        if(!userPlan.name){
            alert('Please, fill in name fields in plan')
            return            
        }
        if(!userPlan.date_plan){
            alert('Please, fill in date_plan fields in plan')
            return            
        }
        if(!userPlan.days){
            alert('Please, fill in days fields in plan')
            return            
        }
        const body = {
            user_id: id,
            ...userPlan,

        }
        const response = await updatePlan(body);
        
        if(!response.error){
            alert('Update success')
            return
        }

        alert('Error on update')
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="container-fluid">
            { loading && <div>carregando...</div>}
            { !loading &&
            <div>
                <a href="/users" type="button" class="btn btn-light mb-4">Voltar</a>
                <div className="col-lg-12 d-flex align-items-stretch">
                    <div className="card w-100">
                        <div className="card-body p-4">
                            <h5 className="card-title fw-semibold mb-4">Details user</h5>
                            <div className="table-responsive">
                                <div class="mb-3">
                                    <label class="form-label">Name</label>
                                    <input value={userDetails.name} onChange={e => setUserDetails({ ...userDetails, name: e.target.value })} type="name" class="form-control"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">E-mail</label>
                                    <input value={userDetails.email} onChange={e => setUserDetails({ ...userDetails, email: e.target.value })} type="email" class="form-control"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Telephone</label>
                                    <input value={userDetails.telephone} onChange={e => setUserDetails({ ...userDetails, telephone: e.target.value })} type="email" class="form-control"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Active</label>
                                    <input value={userDetails.active} onChange={e => setUserDetails({ ...userDetails, active: e.target.value })} type="active" class="form-control"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Type</label>
                                    <input value={userDetails.type} onChange={e => setUserDetails({ ...userDetails, type: e.target.value })} type="type" class="form-control"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Logged whatsapp</label>
                                    <input value={userDetails.isLoggedWp} disabled type="type" class="form-control"/>
                                </div>
                                <button type="button" onClick={updateUser} class="btn btn-primary">Update</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 d-flex align-items-stretch">
                    <div className="card w-100">
                        <div className="card-body p-4">
                            <h5 className="card-title fw-semibold mb-4">Plan user</h5>
                            <div className="table-responsive">
                                <div class="mb-3">
                                    <label class="form-label">Name</label>
                                    <input value={userPlan.name} onChange={e => setUserPlan({ ...userPlan, name: e.target.value })} type="name" class="form-control"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Date plan</label>
                                    <input value={userPlan.date_plan} placeholder='2023-11-21 17:33:05' onChange={e => setUserPlan({ ...userPlan, date_plan: e.target.value })} type="date_plan" class="form-control"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Days</label>
                                    <input type="number" value={userPlan.days} onChange={e => setUserPlan({ ...userPlan, days: e.target.value })} type="days" class="form-control"/>
                                </div>
                                <button type="button" onClick={updatePlanData} class="btn btn-primary">Update</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 d-flex align-items-stretch">
                    <div className="card w-100">
                        <div className="card-body p-4">
                            <h5 className="card-title fw-semibold mb-4">Config user</h5>
                            <div className="table-responsive">
                                <div class="mb-3">
                                    <label class="form-label">Max sending</label>
                                    <input value={userConfig.value} onChange={e => setUserConfig({ ...userConfig, value: e.target.value })} type="max_sending" class="form-control"/>
                                </div>
                                <button type="button" onClick={updateConfigData} class="btn btn-primary">Update</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 d-flex align-items-stretch">
                    <div className="card w-100">
                        <div className="card-body p-4">
                            <h5 className="card-title fw-semibold mb-4">Scheduling user</h5>
                            <div className="table-responsive">
                                <table id="table" className="table text-nowrap mb-0 align-middle">
                                    <thead className="text-dark fs-4">
                                        <tr>
                                            <th className="border-bottom-0">
                                                <h6 className="fw-semibold mb-0">#id</h6>
                                            </th>
                                            <th className="border-bottom-0">
                                                <h6 className="fw-semibold mb-0">User</h6>
                                            </th>
                                            <th className="border-bottom-0">
                                                <h6 className="fw-semibold mb-0">UpdateAt</h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userScheduling.map((sche, index) =>
                                            <tr key={index}>
                                                <td className="border-bottom-0">
                                                    #{sche.id}
                                                </td>
                                                <td className="border-bottom-0">
                                                    {sche.name}
                                                </td>
                                                <td className="border-bottom-0">
                                                    <p className="mb-0 fw-normal">{sche.status == 0 ? 'Pausado' : sche.status == 1 ? 'Concluido' : 'Em andamento'}</p>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default View;
