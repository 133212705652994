
import Cookies from 'js-cookie';
const updateConfig = async (requestData) => {
    const authToken = Cookies.get('authToken');
    if (!authToken) {
        return false;
    }

    let response = await fetch(`${process.env.REACT_APP_API_WEB_BOOT}admin/config`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(requestData)
    });

    if (response.status !== 200) {
        response = await response.json();
        let message = "Ocorreu um erro no registro";    
        return {
            message,
            error: true
        };
    }
    response = await response.json();
    return response;
}

export default updateConfig