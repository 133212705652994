import React, { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import getUsers from '../../helper/user/getUsers';

const Users = () => {
    const [allData, setAllData] = useState([]);

    const fetchData = async () => {
        const users = await getUsers();
        setAllData(users);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Contatos Não Salvos');

        worksheet.columns = [
            { header: 'Número', key: 'number', width: 15 },
            { header: 'Nome', key: 'name', width: 20 },
            { header: 'Email', key: 'email', width: 20 },
        ];

        allData.forEach((user) => {
            worksheet.addRow({
                number: user.telephone,
                name: user.name,
                email: user.email,
            });
        });

        // Criar o arquivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const fileName = 'users.xlsx';
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="container-fluid">
            <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body p-4">
                        <h5 className="card-title fw-semibold mb-4">Users</h5>
                        <div>
                            <button className="btn btn-outline-primary m-1" onClick={exportToExcel}>Exportar</button>
                        </div>
                        <div className="table-responsive">
                            <table id="table" className="table text-nowrap mb-0 align-middle">
                                <thead className="text-dark fs-4">
                                    <tr>
                                        <th className="border-bottom-0">
                                            <h6 className="fw-semibold mb-0">#id</h6>
                                        </th>
                                        <th className="border-bottom-0">
                                            <h6 className="fw-semibold mb-0">User</h6>
                                        </th>
                                        <th className="border-bottom-0">
                                            <h6 className="fw-semibold mb-0">Telefone</h6>
                                        </th>
                                        <th className="border-bottom-0">
                                            <h6 className="fw-semibold mb-0">CreatedAt</h6>
                                        </th>
                                        <th className="border-bottom-0">
                                            <h6 className="fw-semibold mb-0">UpdateAt</h6>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allData.map((user, index) =>
                                        <tr key={index}>
                                            <td className="border-bottom-0">
                                                <a href={`user/view/${user.id}`}>#{user.id}</a>
                                            </td>
                                            <td className="border-bottom-0">
                                                <h6 className="fw-semibold mb-1">{user.name}</h6>
                                                <span className="fw-normal">{user.email}</span>
                                            </td>
                                            <td className="border-bottom-0">
                                                <p className="mb-0 fw-normal">{user.telephone}</p>
                                            </td>
                                            <td className="border-bottom-0">
                                                <p className="mb-0 fw-normal">{user.createdAt}</p>
                                            </td>
                                            <td className="border-bottom-0">
                                                <p className="mb-0 fw-normal">{user.updatedAt}</p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Users;
