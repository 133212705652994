import Aside from '../../template/partial/aside'
import Header from '../../template/partial/header'
import View from '../../template/users/view'

const view = () => {
    return (
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed">
            <Aside/>

            <div class="body-wrapper">
                <Header/>
                <View/>
            </div>

        </div>
    )
}

export default view