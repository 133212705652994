
import Cookies from 'js-cookie';

const getPlan = async (query = '') => {

    const authToken = Cookies.get('authToken');
    if (!authToken) {
        return false;
    }

    let response = await fetch(`${process.env.REACT_APP_API_WEB_BOOT}admin/plan${query}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });

    if (response.status !== 200) {
        return false;
    }
    response = response.json();
    return response;
}

export default getPlan