import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from '../components/login/login';
import Users from '../components/users/users';
import View from '../components/users/view';
import PrivateRoute from './PrivateRoute';

export default props => (
  <Router>
    <Routes>
      <Route path="/users" element={<PrivateRoute>{() => <Users />}</PrivateRoute>}/>
      <Route path="/user/view/:id" element={<PrivateRoute>{() => <View />}</PrivateRoute>}/>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  </Router>
);
